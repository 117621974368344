<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Enquiry FollowUp</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ Enquiry FollowUp
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="addEnquiryFollowup()"
                      class="mt-1 btn btn-primary"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add Enquiry FollowUp
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <v-row>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      label="Lookup by Name, Email"
                      v-model="search.name"
                      v-on:keyup.enter="searchEnquiry"
                      @input="search.name = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-text-field
                      label="Status"
                      v-model="search.status"
                      v-on:keyup.enter="searchEnquiry"
                      @input="search.status = $event !== null ? $event : ''"
                      clearable
                      outlined
                      dense
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <v-btn
                      class="btn btn-primary"
                      @click="searchEnquiry"
                      :loading="loading"
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div class="table-responsive">
              <v-skeleton-loader
                  v-if="loading"
                  type="table-thead"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3" :class="sortedClass('enquiry')" @click="sortBy('enquiry')"><strong>Enquiry</strong></th>
                  <th><strong>Date</strong></th>
                  <th><strong>Added By</strong></th>
                  <th class="pr-3 text-center"><strong>Action</strong></th>
                </tr>
                </thead>
                <tbody>
                <template>
                  <tr v-for="(enquiryFollowup, index) in sortedItems" :key="index" >
                    <td>
                      <a @click="editEnquiryFollowUp(enquiryFollowup)" class="text-primary font-weight-bolder text-hover-primary mb-1 font-size-lg">
                        {{ enquiryFollowup.enquiry }}
                      </a>
                    </td>
                    <td>
                      {{ enquiryFollowup.formatted_date ?enquiryFollowup.formatted_date : '--'}}
                    </td>
                    <td>
                      {{ enquiryFollowup.addedBy ? enquiryFollowup.addedBy : '--' }}
                    </td>
                    <td class="pr-0 text-left">
                      <template>
                        <template>
                          <b-dropdown
                              size="sm"
                              variant="link"
                              toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                              no-caret
                              right
                              no-flip
                          >
                            <template v-slot:button-content>
                              <i class="ki ki-bold-more-hor"></i>
                            </template>
                            <!--begin::Navigation-->
                            <div class="navi navi-hover min-w-md-250px">
                              <b-dropdown-text tag="div" class="navi-item">
                                <a @click="editEnquiryFollowUp(enquiryFollowup)" class="navi-link">
                                <span class="navi-icon">
                                    <v-icon color="blue darken-2">fas fa-pen</v-icon>
                                </span>
                                  <span class="navi-text">Edit</span>
                                </a>
                              </b-dropdown-text>
                              <b-dropdown-text tag="div" class="navi-item">
                                <a class="navi-link" @click.prevent="deleteEnquiry(enquiryFollowup.id)">
                                <span class="navi-icon">
                                    <v-icon color="red darken-2">fas fa-trash</v-icon>
                                </span>
                                  <span class="navi-text">Delete</span>
                                </a>
                              </b-dropdown-text>
                            </div>
                          </b-dropdown>
                        </template>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="sortedItems.length == 0" >
                    <td colspan="4" class="text-center"><strong>No Data Found</strong></td>
                  </tr>
                </template>
                </tbody>
              </table>
              <b-pagination
                  v-if="enquiry_followups.length > 0"
                  class="pull-right mt-7"
                  @input="getAllEnquiryFollowups"
                  :disabled="loading"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  last-number
              ></b-pagination>
            </div>

          </div>
        </div>
      </div>
      <create-and-update ref="create-and-update" @refresh="getAllEnquiryFollowups"></create-and-update>
    </div>
  </v-app>
</template>

<script>
import CreateAndUpdate from "./CreateAndUpdate";
import EnquiryFollowupService from "@/services/enquiry/followup/EnquiryFollowupService";

const enquiryFollowup = new EnquiryFollowupService();
export default {
  name: "Index",
  components: {CreateAndUpdate},
  data() {
    return {
      loading: false,
      total: null,
      perPage: null,
      page: null,
      enquiry_followups: [],
      enquiryId: '',
      search:{
        name: '',
        is_active: ''
      },
      sort: {
        key: '',
        isAsc: false
      }
    }
  },
  mounted(){
    this.enquiryId = this.$route.params.enquiryId;
    this.getAllEnquiryFollowups();
  },
  computed: {
    sortedItems() {
      const list = this.enquiry_followups.slice();
      if (!!this.sort.key) {
        list.sort((a, b) => {
          a = a[this.sort.key]
          b = b[this.sort.key]
          return (a === b ? 0 : a > b ? 1 : -1) * (this.sort.isAsc ? 1 : -1)
        });
      }
      return list;
    }
  },
  methods: {
    sortedClass(key) {
      return this.sort.key === key ? `sorted ${this.sort.isAsc ? 'asc' : 'desc'}` : '';
    },
    sortBy(key) {
      this.sort.isAsc = this.sort.key === key ? !this.sort.isAsc : false;
      this.sort.key = key;
    },
    getAllEnquiryFollowups() {
      enquiryFollowup
          .paginate(this.search, this.page)
          .then((response) => {
            this.enquiry_followups = response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    searchEnquiry() {
      this.getAllEnquiryFollowups();
    },
    addEnquiryFollowup() {
      this.$refs['create-and-update'].addEnquiryFollowup(this.enquiryId);
    },
    editEnquiryFollowUp(enquiryFollowup) {
      this.$refs['create-and-update'].editEnquiryFollowUp(enquiryFollowup);
    },
    deleteEnquiry(id) {
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            enquiryFollowup
                .delete(id)
                .then((response) => {
                  this.getAllEnquiryFollowups()
                  this.$snotify.success("Enquiry FollowUp Deleted Successfully ");
                })
                .catch((err) => {
                  this.$snotify.error("Oops something went wrong");
                });
          }
        }
      });
    },
  }
}
</script>

<style lang="sass">
table
  th.sorted
    &.asc::after
      display: inline-block
      content: '▼'
      color: red

    &.desc::after
      display: inline-block
      content: '▲'
      color: red

</style>